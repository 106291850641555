h2 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

hr {
  margin: 2em 0 2em 0;
  color: black;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 10 10 10 10;
  margin: 2em 4em 2em 1em;
  background-color: var(--bg-colour);
}

.navbar {
  position: fixed;
  margin: 2em 0 0 3em;
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
}

.navItem {
  padding: 0.5em 0 0.5em 0.5em;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  margin: 0.5em 0 0.5em 0;
  background: var(--text-colour);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navItem:hover {
  cursor: pointer;
  animation: navItemHover 0.3s ease-in-out 0s 1 normal both;
  background: var(--primary-colour);
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: #ffffff;
}

.card-container {
  margin: 1em 3.8em 0 0;
}

.dropcard-container {
  margin: 1em 3.8em 0 0;
}

.main {
  width: 70%;
  margin: 5em 0 0 25em;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: row;
}

.profile-picture {
  width: 20em;
  height: 30em;
  border-radius: 100px;
  border: 5px;
  object-fit: cover;
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.5);
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 3em;
}

.landing-container {
  margin: 0 3em 0 0;
}

.landing-title-container {
  width: fit-content;
  justify-content: left;
  align-items: left;
}

.landing-title {
  justify-content: left;
  align-content: baseline;
}

.landing-subtitle {
  text-align: justify;
}

.line-1 {
  top: 50%;
  margin: 0 0 0 0;
  border-right: 2px solid var(--primary-colour);
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

.anim-typewriter {
  animation: typewriter 1s steps(14) 1s 1 normal both,
    blinkTextCursor 700ms steps(14) infinite normal;
}

.signature {
  margin: 1em 0 0 0;
}

.social-media-icon {
  margin: 2em 1em 0 0em;
}

.social-media-list {
  display: flex;
  flex-direction: row;
}

.section {
  margin: 4em 0 0 0;
}

.section-title {
  display: block;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  background: var(--section-colour);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title::before {
  content: ">";
  color: #ffffff;
  position: relative;
  bottom: 2px;
  right: 8px;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: var(--primary-colour);
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.section-divider {
  display: flex;
  align-items: right;
  border: none;
  margin: 0 0 0 1.5em;
  transform: translateY(4px);
  border-top: 2.2px dashed rgba(70, 70, 70, 0.61);
  color: #fff;
  background-color: #fff;
  height: 1px;
}

@keyframes typewriter {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: var(--primary-colour);
  }
  to {
    border-right-color: transparent;
  }
}

@keyframes navItemHover {
  from {
    background: var(--text-colour);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  to {
    background: var(--primary-colour);
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: #ffffff;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  }
}
